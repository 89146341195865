@import 'styles/variables.scss';
.InputRow {
  width: 100%;
  display: flex;
  flex-direction: row;

  @include media_830 {
    flex-direction: column;
  }
}

.QuestCard {
  display: flex;
  flex-direction: row;
  width: 100%;
  border-radius: 20px;
  background: var(--secondary-60, #472F66);
  padding: 20px;

  @include media_830 {
    flex-direction: column;
  }
}

.Image {
  @include media_830 {
    display: none;
  }
}

.MainQuestImage {
  border-radius: 10px;
}

.Block2 {
  width: calc(100% - 150px);
  margin-left: 20px;

  @include media_830 {
    width: 100%;
    margin-left: 0;
  }
}

.QuestName {
  width: 100%;
  color: var(--neutral-10, #FFF);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  @include media_830 {
    margin-top: 0;
  }
}

.QuestAddress {
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 20px;
}

.QuestAddress span {
  color: var(--neutral-20, #CAC6CE);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  margin-left: 10px;
}

.QuestInfo {
  position: absolute;
  top: 50px;
  right: 10px;

  @media screen and (max-width: 600px) {
    top: 35px;
  }
}

.QuestInfo:nth-child(1) {
  right: 0px !important;
}

.QuestInfoText {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.QuestInfoText span {
  margin-left: 7px;
  color: rgb(236, 236, 236);
}

.QuestInfoText:not(:first-child) {
  margin-top: 6px;

  @media screen and (max-width: 600px) {
    margin-top: 0px;
  }
}

.CardQuestInfo {
  width: 100%;
  display: flex;
  // margin: 30px 0;

  @include media_830 {
    margin: 15px 0;
  }
}

.CardQuestInfoBlock {
  width: 50%;

}

.CardQuestInfoBlockItem {
  display: flex;
  align-items: center;

  &:not(:first-child) {
    margin-top: 10px;
  }

  & span {
    color: var(--neutral-20, #CAC6CE);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin-left: 10px;
  }
}

.Title {
  color: var(--neutral-10, #FFF);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  margin-bottom: 20px;
}

.Description {
  color: var(--neutral-10, #FFF);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  margin-bottom: 30px;
}