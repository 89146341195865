@import 'styles/variables.scss';
.MainInfoBlockHeader {
  position: absolute;
  top: 80px;
  right: 0;
  height: 65px;
  width: 100%;
  display: flex;
  align-items: center;
  background: linear-gradient(100deg, #fff 18.31%, rgb(218, 217, 217) 99.67%);
  justify-content: flex-end;
  padding: 10px 50px;
  z-index: 1;

  @include media_830 {
    padding: 10px 20px;
    height: auto;
    min-height: 65px;
    max-height: 200px;
  }
}

.MainInfoBlockHeaderLinksWrapper {
  display: flex;

  @include media_830 {
    flex-direction: column;
    width: 100%;
  }
}