@import 'styles/variables.scss';
.BokingCardInfoItemHead {
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 500;
  color: #000;
  margin-bottom: 7px;
  font-family: 'CabinBold';
}

.BokingCardInfoItem {
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 500;
  color: #555;
  margin-bottom: 7px;

  input {
    width: 100%;
  }

  span,
  a {
    width: 50%;
    color: #000;
  }

  a {
    cursor: pointer;
    transition: .3s;

    &:hover {
      opacity: .5;
    }
  }
}

.BokingCardInfoTitle {
  margin-top: 20px;
  padding: 5px;
  width: 100%;
  text-align: center;
  font-size: 22px;
  background: #999;
  color: #FFF;
}

.BokingCardInfoTable {
  padding: 10px 20px;
  box-shadow: 0px 4px 40px 0px rgba(12, 0, 47, 0.20);
}

.BokingCardInfoItemPrice {
  margin-top: 20px;
  text-align: center;
  color: #ffffff;
  background-color: #000;
  padding: 10px;
  font-family: 'CabinBold';

  a {
    color: rgb(243, 108, 108);
  }
}

.ButtonsWrapper {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;

  @include media_830 {
    flex-direction: column;
    gap: 10px;
  }
}

.ButtonsWrapperSecond {
  display: flex;
  justify-content: space-between;
  @include media_830 {
    flex-direction: column;
    gap: 10px;
  }
}

.ButtonStyle {
  width: 32%;

  @include media_830 {
    width: 100%;
  }
}

.ButtonStyleSecond {
  width: 49%;

  @include media_830 {
    width: 100%;
  }
}

.ButtonEdit {
  width: 100%;
  margin-top: 15px;

  @include media_830 {
    margin-top: 10px;
  }
}

.ButtonZaglushka {
  width: 100%;
}

.MailField {
  word-break: break-all;
}