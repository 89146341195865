@import 'styles/variables.scss';
.ModalWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  visibility: none;
  opacity: 0;
  transition: .3s;
  z-index: -1;
}

.ModalWrapperActive {
  visibility: visible;
  opacity: 1;
  z-index: 999;
}

.ModalCard {
  position: relative;
  max-width: 600px;
  min-width: 600px;
  max-height: 90%;
  overflow-y: scroll;
  padding: 40px 60px;
  border-radius: 20px;
  background: var(--neutral-10, #FFF);
  box-shadow: 0px 10px 40px 0px rgba(1, 21, 40, 0.20);


  @include media_830 {
    min-width: 95%;
    max-width: 95%;
    padding: 30px 20px;
    padding-top: 40px;
    max-height: 70vh;
  }

  &::-webkit-scrollbar {
    width: 0px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #2A1544;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: #472F66;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #2A1544;
  }
}

.ModalCloseButton {
  position: absolute;
  top: 20px;
  right: 20px;
  transform: rotateZ(0);
  transition: .3s;
  cursor: pointer;

  @include media_830 {
    top: 15px;
  }

  &:hover {
    transform: rotateZ(-180deg);
  }
}