@import 'styles/variables.scss';
.BookingListContainer {
  position: relative;
}

.QuestNameItemsWrapper {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;


  @include media_830 {
    display: none;
  }
}

.QuestNameItemsWrapperMobile {
  display: none;
  flex-wrap: wrap;
  margin-bottom: 30px;


  @include media_830 {
    display: flex;
  }
}

.QuestNameItem {
  width: max-content;
  border-radius: 10px;
  padding: 10px;
  font-size: 15px;
  margin: 10px 10px 0 0;
  font-family: 'CabinMedium';
  transition: .3s;
  box-shadow: 0px 2px 10px 0px rgba(1, 9, 40, 0.10);
  cursor: pointer;

  &:hover {
    background-color: #333;
    color: #FFF;
    box-shadow: none;
  }
}

.BookingItemCanceled {
  opacity: .2;
  border: 1px solid gray;
  pointer-events: none;
}

.QuestNameItemActive {
  background-color: #333;
  color: #FFF;
  box-shadow: none;
}

.BookingSchedule {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 10px;
  padding-bottom: 10px;

  @include media_830 {
    flex-direction: column;
  }
}

.BookingScheduleIsDashed {
  border-bottom: 1px dashed #ACB4D2;
}

.BookingDay {
  width: 200px;
  color: var(--secondary-80, #13002C);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;


  @include media_830 {
    width: 100%;
    margin-top: 10px;
  }
}

.BookingDayDay {
  color: var(--secondary-70, #5D5D81);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

.BookingDayDayWeekends {
  color: rgb(251, 83, 83);
}

.IsShowMore {
  display: none;
}

.BookingListWrapper {
  width: calc(100% - 200px);
  display: grid;
  grid-template-columns: repeat(auto-fill, 86px);
  justify-content: space-between;

  @include media_830 {
    width: 100%;
  }
}

.BookingItem {
  min-width: 86px;
  max-width: 86px;
  border-radius: 10px;
  background: rgb(244, 244, 244);
  padding: 6px 0;
  margin-top: 10px;
  transition: .3s;
  cursor: pointer;

  &:hover {
    opacity: .8;
  }
}

.BookingItemOrdered {
  background: rgba(252,211,77) !important;
}

.BookingItemAccepted {
  background: rgba(110,231,183);
}

.BookingItemFixed {
  background: rgb(183, 187, 192);
}

.BookingItemTime {
  color: var(--secondary-80, #000);
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  /* 27px */
  transition: .3s;
}

.BookingItemTimeDisabled {
  color: var(--secondary-60, #472F66);
}

.BookingItemPrice {
  color: var(--secondary-70, #5D5D81);
  text-align: center;
  margin-top: 6px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  transition: .3s;
}

.BookingItemPriceDisabled {
  color: var(--secondary-60, #472F66);
}

.isIframeWrapper {
  padding: 0 20px;
  width: 100% !important;
}

.TransferBooking {
  position: fixed;
  top: 15px;
  left: 50%;
  border-radius: 10px;
  transform: translate(-50%, 10%);
  padding: 20px;
  box-shadow: 0px 2px 10px 0px rgba(1, 9, 40, 0.10);
  background-color: #FFF;
  z-index: 100;
}

.TransferBookingText {
  margin-bottom: 5px;
  color: var(--secondary-60, #472F66);
  text-align: center;
  font-size: 15px;
}

.TransferBookingButtonCancel {
  margin-top: 10px;
  background-color: #f65f5f;
  padding: 5px 10px;
  border-radius: 6px;
  text-align: center;
  color: #FFF;
  transition: .3s;
  cursor: pointer;

  &:hover {
    background-color: #f25252;
  }
}

.Loader {
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.TransferBookingWrapper {
  position: fixed;
  top: 90px;
  left: 50%;
  transform: translate(-50%, 0);
  background: #fff;
  z-index: 99999;
  padding: 10px;
  box-shadow: 0px 2px 10px 0px rgba(1, 9, 40, 0.10);

  @include media_830 {
    min-width: 90%;
  }
}

.PotencialMoney {
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  margin: 20px 0;
  box-shadow: 0px 2px 10px 0px rgba(1, 9, 40, 0.10);
  text-transform: uppercase;
  border-radius: 10px;
}

.PotencialMoneyPrice {
  font-family: 'Muller Extra Bold';
}