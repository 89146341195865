@import 'styles/variables.scss';
.Button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  padding: 10px 16px;
  transition: .25s;
  cursor: pointer;
}

.ButtonSecondary {
  border: 1px solid $borderSecondaryColor;

  .ButtonLabel {
    color: $textSecondary70Color;
    // @include textNormalFont16;
  }

  &:hover {
    border-color: $borderSecondaryHoverColor;
  }
}

.ButtonPrimary {
  background-color: rgba(110,231,183);
  transition: .3s;

  .ButtonLabel {
    color: $textSecondary70Color;
    // @include textNormalFont16;
  }

  &:hover {
    background-color: rgb(35, 237, 156);
  }
}

.ButtonDelete {
    background-color: #f12c15;
    transition: .3s;
  
    .ButtonLabel {
      color: #FFF;
      // @include textNormalFont16;
    }
  
    &:hover {
      background-color: #d52713;
    }
}

.ButtonLabelWithIcon {
  margin-left: 10px;
}