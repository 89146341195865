@import 'styles/variables.scss';
.Wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 4px;
  padding: 150px 40px 40px 40px;

  @include media_670 {
    padding-bottom: 0;
    padding: 150px 20px 20px 20px;
  }
}