@import 'styles/variables.scss';
.Input {
  width: 100%;
  padding: 10px 15px;
  border: 1px solid $inputBorderColor;
  background: #fff;
  outline: none;
  color: #000;
  margin-top: 10px;
}

.InputErrorField {
  border-color: red;
}

.InputWithOutMargins {
  margin-top: 0;

  label {
    top: 33% !important;
  }
}

.InputWrapper {
  width: 100%;
  margin: 0 !important;
  position: relative;
  display: inline-block;
}

.Label {
  padding: 0 10px;
  pointer-events: none;
  position: absolute;
  left: 10px;
  top: 50%;
  transition: 0.15s;
  transition-timing-function: ease;
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  opacity: 1;
  transform: translateY(-140%) translateX(0px);
  color: #000;
  background: #fff;
  font-size: 12px;
}

.LabelWithOutMargins {
  top: 30%;
}

.InputDisabled {
  opacity: .7;
  border: 1px solid #E1E2EF;

  label {
    color: #E1E2EF !important;
  }
}

.SearchIcon {
  position: absolute;
  top: 7px;
  left: 10px;
  z-index: 999999;
}

.SearchebleCrossIcon {
  position: absolute;
  top: 0%;
  right: 20px;
  transform: translate(0%, -10%);
  z-index: 99999;
  transition: .3s;
  cursor: pointer;

  &:hover {
    opacity: .7;
  }
}