//colors
$secodaryColor: #080809;

$gray400: #9ca3af;


//mixins
@mixin xsmall {
  @media (max-width: 480px) {
    @content;
  }
}

@mixin media_340 {
  @media (max-width: 340px) {
    @content;
  }
}

@mixin media_530 {
  @media (max-width: 530px) {
    @content;
  }
}

@mixin media_650 {
  @media (max-width: 650px) {
    @content;
  }
}

@mixin media_670 {
  @media (max-width: 670px) {
    @content;
  }
}

@mixin media_720 {
  @media (max-width: 720px) {
    @content;
  }
}

@mixin media_830 {
  @media (max-width: 830px) {
    @content;
  }
}

@mixin media_900 {
  @media (max-width: 900px) {
    @content;
  }
}

@mixin media_1020 {
  @media (max-width: 1020px) {
    @content;
  }
}

@mixin media_1080 {
  @media (max-width: 1080px) {
    @content;
  }
}

@mixin media_1120 {
  @media (max-width: 1120px) {
    @content;
  }
}

@mixin media_1172 {
  @media (max-width: 1172px) {
    @content;
  }
}

@mixin media_1180 {
  @media (max-width: 1180px) {
    @content;
  }
}

@mixin media_1280 {
  @media (max-width: 1280px) {
    @content;
  }
}

$mainAppColor: #1F3EDE;
$secondaryAppColor: #FFCD1E;
$secondaryAppHoverColor: #FFCD1E;

$borderSecondaryColor: #5D5D81;
$borderSecondaryHoverColor: #FFB21E;

$filterBorderColor: #E1E2EF;

$textSecondary70Color: #13002C;

$inputBorderColor: #797D93;

$menuWidth: 316px;
$sidebarWidth: 270px;

$primaryColor: #0B5CD7;
$descriptionColor: #616161;
$borderGrayColor: #E0E0E0;
$contentBackground: #fff;
$secondaryTextColor: #424242;
$textLightColor: #fff;
$textDeepColor: #0A0A0A;
$dangerBackground: #FFD7D7;
$dangerTextColor: #D72C2C;

//Skeleton
$skeletonBorderColor: #B6CFF3;
$skeletonItemColor: #C3D9FD;
$skeletonAnimationColor: #ECF2FC;


//Auth
$authTextColor: #FFFFFF;
$authFooterTextColor: #616161;

//Button
$primaryBackgroundButtonColor: #0B5CD7;
$hoverPrimaryBackgroundButtonColor: #044DBA;

//Input
$mainInputBorderColor: #E0E0E0;

//Header
$headerBorderColor: #E4E9ED;
$headerActiveNavLinkColor: #043C8F;
$headerItemBackgroundColor: #ECF2FC;
$headerCounterBackground: #D72C2C;




$actionColor: #0d4684;
$accentColor: #212a33;
$textColor: #404b57;
$placeholderColor: #b3b3b3;
$buttonLightColor: #e4e9ed;
$buttonSecondColor: #ffc86b;
$borderColor: #6894c3;
$borderLightColor: #cbcaca;
$cardColor: #eceeef;
$formUploadColor: #e8e8e8;
$backgroundLightColor: #f1f4f6;
$whiteColor: #ffffff;
$mainBlackColor: #000000;
$errorRedColor: #d72c2c;
$selectErrorColor: #b42638;
$mediumRedColor: #de350b;
$deepRedColor: #ae1f24;
$lightRedColor: #eb5757;
$deepGrayColor: #252629;
$deepGrayColor1: #2a2a2a;
$lightGrayWhiteColor: #dbdbdb;
$lightGrayColor: #f2f2f2;
$lightGrayColor1: #e6e6e6;
$lightGrayColor2: #f7f7f7;
$lightGrayColor3: #eaeaea;
$lightGrayColor4: #ececec;
$lightGrayColor5: #efefef;
$lightGrayColor6: #c4c4c4;
$lightGrayColor7: #fff4e0;
$lightGrayColor8: #e8e6e5;
$lightGrayColor9: #dee6ec;
$lightGrayColor10: #f8f8f8;
$lightGrayColor11: #fff2f2;
$lightGrayColor12: #D7D7D7;
$lightGrayColor13: #FDEEEE;
$mediumGrayColor: #666;
$mediumGrayColor1: #6a7580;
$mediumGrayColor2: #dbdfe2;
$mediumGrayColor3: #808083;
$mediumGrayColor4: #595959;
$mediumGrayColor5: #a9a9a9;
$mediumGrayColor6: #63666a;
$mediumGrayColor7: #545454;
$mediumGrayColor8: #303434;
$mediumGrayColor9: #3F4952;
$mediumGrayColor10: #414951;
$ligthYellowColor1: #ffe9c0;
$mediumYellowColor: #ffcf1c;
$ligthYellowColor: #ffe500;
$orangeColor: #d4691b;
$greyBlueColor: #afbdc6;
$lightGreyBlueColor: #4fa9c2;
$lightBlueColor: #4070a6;
$lightBlackColor: #333333;
$mediumBlackColor: #222222;
$deepWhiteColor: #feedef;
$lightPinkColor: #f9c4c4;
$auqaColor: #65C1BE;

$rgbaBlackColor: rgba(255, 255, 255, 0.1);

$primaryShadow: 0 2px 30px 0 rgba(3, 30, 125, 0.05), 0 2px 20px 0 rgba(3, 24, 98, 0.05);

$shadow025: rgba(0, 0, 0, 0.25);
$shadow015: rgba(0, 0, 0, 0.15);
$shadow007: rgba(0, 0, 0, 0.07);
$shadow005: rgba(0, 0, 0, 0.05);
$shadow004: rgba(0, 0, 0, 0.4);
$shadow002: rgba(0, 0, 0, 0.2);
