@import 'styles/variables.scss';
.Select {
  position: relative;
  margin-top: 10px;
  width: 100%;
}

.SelectPlaceholder {
  padding: 0 10px;
  pointer-events: none;
  position:absolute;
  left:10px;
  top:-10px;
  transition: 0.15s;
  transition-timing-function: ease;
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  color: #000;
  background: #fff;
  font-size: 12px;
  z-index: 10;
}